import {ExtensibleEntityDto} from './ExtensibleEntityDto'
import {PagedAndSortedResultRequestDto} from './PagedAndSortedResultRequestDto'
import {Guid} from 'guid-typescript';

export interface TrainingTypeDto extends ExtensibleEntityDto <Guid> {
  name: string
  remark: string
}

export class CreateOrUpdateTrainingTypeDto {
  public name: string = ''
  public remark: string = ''
}

export class GetListTrainingTypeDto extends PagedAndSortedResultRequestDto {
  public filter: string = ''
}
